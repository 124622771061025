<template>
    <page-placeholder v-if="project.isLoaded">
        <v-row>
            <v-col :xl="4" :lg="4" :md="4" :cols="12">
                <market-segment-size/>
            </v-col>
            <v-col :xl="8" :lg="8" :md="8" :cols="12">
                <market-segment-competitor-compare/>
            </v-col>


            <v-col :xl="6" :lg="6" :cols="12">
                <market-segment-segment-size/>
            </v-col>
            <v-col :xl="6" :lg="6" :cols="12">
                <market-segment-segment-performance/>
            </v-col>


            <v-col :cols="12">
                <market-segment-visibility/>
            </v-col>


            <v-col :cols="12">
                <market-segment-best-progression-potential/>
            </v-col>
        </v-row>
    </page-placeholder>
</template>

<script>
import PagePlaceholder from "@/components/yooda-components/PagePlaceholder";

import MarketSegmentSize from "@/components/yooda-components/app/dashboard/MarketSegmentSize";
import MarketSegmentCompetitorCompare from "@/components/yooda-components/app/market-segment/MarketSegmentCompetitorCompare";
import MarketSegmentSegmentSize from "@/components/yooda-components/app/market-segment/MarketSegmentSegmentSize";
import MarketSegmentSegmentPerformance from "@/components/yooda-components/app/market-segment/MarketSegmentSegmentPerformance";
import MarketSegmentVisibility from "@/components/yooda-components/app/market-segment/MarketSegmentVisibility";
import MarketSegmentBestProgressionPotential from "@/components/yooda-components/app/market-segment/MarketSegmentBestProgressionPotential";

export default {
    components: {
        PagePlaceholder,
        MarketSegmentSize,
        MarketSegmentCompetitorCompare,
        MarketSegmentSegmentSize,
        MarketSegmentSegmentPerformance,
        MarketSegmentVisibility,
        MarketSegmentBestProgressionPotential,
    },
}

</script>
